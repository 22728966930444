html,
body {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(68, 68, 68, 0.16);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-border-radius: 3.5px;
    border-radius: 3.5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-button {
    background-image: none;
    background-repeat: no-repeat;
    width: 0;
    height: 0;
  }
}

.loginLayout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContent {
  max-width: 568px;
  flex-grow: 1;
  padding: 40px 36px;

  .logoHolder {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
}

.dashboard {
  .main {
    background-image: url("#{$imgPath}/background-logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 11%;
    @media (min-width: $screen-large) {
      background-size: 16%;
    }
  }
}

.mainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &.introImg {
    position: relative;
    overflow: hidden;
    .mainHeader {
      position: relative;
      z-index: 2;
      box-shadow: none;
    }
    .mainFooter {
      position: relative;
      z-index: 2;
      background: transparent;
      box-shadow: none;
    }
    .videoHolder {
      display: block;
    }
    //  &:before {
    //    content: "";
    //    position: absolute;
    //    display: block;
    //    top: 100px;
    //    left: 0;
    //    width: 100%;
    //    height: calc(100% - 100px);
    //    background-image: url("#{$imgPath}/anim.svg");
    //    background-size: cover;
    //    background-position: center;
    //    background-repeat: no-repeat;
    //  }
  }
  .main {
    flex-grow: 1;
    &.logo {

    }
  }
  .mainFooter {
    .langDropdownHolder {
      padding-bottom: 16px;
    }
    &.empty {
      height: 48px;
    }
  }
}

.videoHolder {
  display: none;
  video {
    position: absolute;
    display: block;
    top: 45px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    outline: none;
    &.lightThemeVideo {
      display: block;
    }
    &.darkThemeVideo {
      display: none;
    }
  }
}

.authFromBlock {
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(68, 68, 68, 0.16);
  background-color: #ffffff;
  width: 100%;
  padding: 24px;
  .passwordInput {
    position: relative;
    .link {
      position: absolute;
      right: 0;
      color: rgba(68, 68, 68, 0.88);
      text-decoration: none;
      padding: 12px;
      font-size: 13px;
      top: 16px;
      line-height: 1.7;
    }
    .MuiOutlinedInput-input {
      padding-right: 76px;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    border-radius: 0;
    -webkit-text-fill-color: rgba(68, 68, 68, 0.88) !important;
  }
}

.authForm {
  .btnHolder {
    margin-top: 22px;
  }
  &.hasHeight {
    height: 164px;
    display: flex;
    flex-direction: column;
    .btnHolder {
      margin-top: auto;
    }
    &.xl {
      height: 204px;
    }
  }
}

.mainContainer {
  margin: auto;
  padding: 0 15px;
}

.themesList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li + li {
    margin-left: 24px;
  }

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &:after {
      content: "";
      display: block;
      background-image: url("#{$imgPath}/bold-round-check-18px.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 2px;
      margin-top: -9px;
      transform: scale(0);
      transition: transform 0.2s;
    }

    &.active {
      &:after {
        transform: scale(1);
      }
    }

    &.blue {
      &:before {
        background-color: #006ae7;
      }
    }

    &.prime {
      &:before {
        background-color: #0094c2;
      }
    }

    &.dark {
      &:before {
        background-color: #252525;
      }
    }
  }
}

.langList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li + li {
    margin-left: 24px;
  }

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 8px;
      border: solid 1px rgba(68, 68, 68, 0.16);
    }

    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 6px;
      background: rgba(68, 68, 68, 0.8);
      margin-top: -5px;
      transform: scale(0);
      transition: transform 0.2s;
      border-radius: 50%;
    }

    &.active {
      &:after {
        transform: scale(1);
      }
    }
  }
}



.mainHeader {
  padding: 10px 15px;
  box-shadow: 0 1px 0 0 rgba(85, 85, 85, 0.02),
    0 8px 16px 0 rgba(85, 85, 85, 0.08);
  background-color: #ffffff;
  .emptyPlaceHolder {
    width: 32px;
    margin-left: auto;
    margin-right: 15px;
    cursor: pointer;
    .MuiIconButton-root {
      padding: 8px;
    }
    .CircularProgressbar {
      .CircularProgressbar-text {
        font-size: 30px;
        fill: $primaryColor;
      }
      .CircularProgressbar-path {
        stroke: $primaryColor;
      }
    }
  }
  .MuiOutlinedInput-input.MuiSelect-selectMenu {
    height: auto;
  }
  .logo {
    // margin-right: 36px;
  }
  .mainMenuHolder {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .userInfoHolder {
    margin-left: auto;
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 15px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  .mobileDrawer {
    display: block;
  }
}

//mobile
@media (max-width: $screen-med) {
  .mainHeader {
    .mainMenuHolder {
      margin-right: auto;
    }
    .changeCompanyHeader {
      display: none;
    }
  }
}


//tablet
@media (min-width: $screen-large) {
  .mainHeader {
    .changeCompanyHeader {
      display: block;
    }
  }
}

//desktop
@media (max-width: $screen-large) {
  .mainHeader {
    .mainMenuHolder {
      margin-right: auto;
    }
    .mobileDrawer {
      display: block;
    }

  }
}

@media (max-width: $screen-xlarge) {
  .mainMenu a {
    font-size: 12px!important;
  }
}

@media (min-width: $screen-xlarge) {
  .changeCompanyHeader {
    width: 200px;
  }
}

.mobileMenuLine {
  border: 1px solid #f2f2f2;
}



.placeUsedProgressHolder {
  padding: 15px;
  min-width: 280px;
  .caption {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .prgHolder {
    position: relative;
    height: 5px;
    border-radius: 10px;
    background-color: #ededed;
    overflow: hidden;
  }
  .prgQnt {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $primaryColor;
  }
}

.modalSm {
  .MuiDialog-paperWidthSm {
    padding: 24px;
  }
}

.mainMenu {
  display: none;
  &.mobile {
    display: block;
    padding: 0;
    width: 240px;
    .MuiTypography-body1 {
      font-size: 13px;
    }
    .MuiListItem-root {
      padding-top: 6px;
      padding-bottom: 6px;
      cursor: pointer;
      &:hover {
        background-color: #fafafa;
      }
    }
    li + li {
      margin-left: 0;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
    .MuiCollapse-wrapperInner {
      .MuiList-root {
        padding-left: 15px;
      }
    }
  }
}

.changeCompanyItem {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 13px;
  padding-top: 12px!important;
  padding-bottom: 12px!important;
}

.changeDepartmentItem {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 13px;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}
.pageBlock {
  &.hasPadTop {
    padding-top: 16px;
  }

  &.hasPadBottom {
    padding-bottom: 16px;
  }
}

.pageCard {
  box-shadow: 0 1px 5px 0 rgba(68, 68, 68, 0.16);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  &.noPadding {
    padding: 0;
  }
}

.pageBtnSection {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  .part {
    display: flex;
    order: 2;
  }
  .part + .part {
    margin-top: 15px;
  }
  .btnGroup {
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 100%;
      display: flex;
      // justify-content: center;
    }
    .item + .item {
      margin-top: 15px;
    }
    .item + .item  + .item {
      width: 115px;
    }
    .item + .item +.item a {
      padding: 0 20px 0 20px;
    }
  }
}

.jobItemName {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(68, 68, 68, 0.88) !important;
  display: flex;
  justify-content: center;
  transition: color 0.3s ease;

  &:hover {
    color: #006ae7 !important;
  }
}

.btnGroup_Jobs {
  display: flex;
  .btnGroup_Jobs_item {
    margin-right: 15px;
    width: 118px;
  }
  .btnGroup_Jobs_item + .btnGroup_Jobs_item + .btnGroup_Jobs_item {
    width: 118px;
    margin-right: 0;
  }
}

@media (max-width: 460px) {
  .btnGroup_Jobs_item + .btnGroup_Jobs_item + .btnGroup_Jobs_item {
    position: absolute;
    top: 125px;
    margin-top: 15px;
  }
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.copyright {
  font-size: 13px;
  color: rgba(68, 68, 68, 0.56);
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
}

@media (max-width: $screen-med) {
  .copyright {
    display: none;
  }
}

.mobileCopyright {
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 10px;
}

.menuListWrapper {
  height: calc(100vh - 15%); 
  overflow: auto;
}

.mobileMenuFooter {
  position: absolute;
  bottom: 2%;
  background-color: #fff;
}

.mobileSettingsMenu {
  font-size: 50px;
}

.respModal {
  .mb10 {
    margin-bottom: 10px;
  }
  &.autoHeight {
  }
  &.calendarModal {
    .MuiDialog-paperWidthSm {
      max-width: 664px;
    }
  }
  &.logsModal {
    .MuiDialog-paperWidthSm {
      max-width: 80% !important;
  }
  .logsTable {
    box-shadow: 0 1px 5px 0 rgba(68, 68, 68, 0.16);
    background-color: #ffffff;
    padding: 0px !important;
    border-radius: 6px;
  }
  }
  .MuiDialog-paperScrollPaper {
    //display: block;
    max-height: unset;
    height: auto;
    margin: auto 16px;
  }
  .MuiDialog-scrollPaper {
    //align-items: flex-start;
    overflow-y: auto;
  }
  .checkboxListHolder {
    flex-grow: 1;
    height: auto;
  }
  .MuiDialog-paper {
    margin: auto 16px;
  }
  .MuiDialog-paperWidthSm {
    padding: 16px;
  }
  .checkboxListContainer {
    overflow-x: auto;
  }
  .btnHolder {
    &.fixedWidthBtn {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      button {
        width: auto;
        min-width: 100px;
        margin-right: 15px;
        // order: 1;
        justify-content: center;
      }
      button + button {
        margin-right: 15px;
        margin-top: 0;
        order: 2;
        width: auto;
      }
      .MuiButton-containedPrimary {
        order: 2;
        margin-top: 0;
        margin-bottom: 15px;
        margin-right: 0px;
      }
      @media (max-width: 480px) {
        // &.fixedWidthBtn {
        //   justify-content: center;
        // }


        .MuiButton-containedPrimary {
          min-width: 100px;
          margin-bottom: 0;
          font-size: 13px;
        }

        button {
          width: auto;
        }
        button + button {
          width: 100px;
          margin-right: 15px;
          margin-bottom: 15px;
          font-size: 11px;
        }
      }
    }
  }
  &.scrollContentModal {
    .MuiDialog-paperWidthSm {
      padding: 0;
    }
  }
}

//Google maps styles

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}
.gmnoprint div {
  background:none !important;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

//

.mapModal {
  .MuiDialog-paperWidthSm {
    padding: 16px;
    max-width: 1000px;
  }
}
.mapContainer {
    box-shadow: 0 1px 5px 0 rgba(68, 68, 68, 0.16);
    background-color: #ffffff;
    padding: 45px 5px 5px 5px;
    border-radius: 6px;
    overflow: hidden;
}

.scrollContentModal {
  form {
    height: 100%;
  }
  .formContentHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .modalTitleHolder {
    flex-shrink: 0;
    padding: 16px 24px;
    border-bottom: 1px solid $borderColor;
    text-align: center;
  }
  .formContent {
    flex-grow: 1;
    overflow-y: auto;
    .formContentBlock:last-child {
      border-bottom: 0;
    }
  }
  .btnHolder {
    flex-shrink: 0;
    padding: 16px 24px;
    border-top: 1px solid $borderColor;
  }
  .formContentBlock {
    border-bottom: 1px solid $borderColor;
    padding: 8px 24px;
    .halfBlockContainer {
      margin: 0 -15px;
    }
    .halfBlock {
      padding: 0 15px;
    }
    .textFieldGroup {
      display: inline-flex;
      align-items: center;
      width: 100%;
      &.hasMargin {
        margin: 8px 0 4px;
      }
      .tfgItem {
        flex: 0 1 100%;
        &.btnItem {
          flex: 0 1 auto;
        }
      }
      & > * + * {
        margin-left: 15px;
      }
      @media (max-width: 480px) {
      &.markAsCompletedGroup {
        flex-wrap: wrap;
      }
      & > * + * {
        margin-left: 0px;
      }
    }
    }
        .formCheckBoxHolder {
          align-items: center;
          height: 36px;
          margin-top: 4px;
          margin-bottom: 8px;
    
          .MuiTypography-root {
            font-size: 13px;
            line-height: 1.2;
          }
    
          .MuiFormControlLabel-root {
            font-size: 13px;
            color: #969696;
          }
    
          &.hasError {
            .MuiCheckbox-root {
              color: #e72222;
            }
    
            .MuiFormControlLabel-root {
              color: #e72222;
            }
          }
    
          .MuiCheckbox-root {
            color: rgb(43 43 43 / 54%);
            &:checked {
              color: rgb(43 43 43 / 54%) !important;
            }
          }
        }
    &.noBorder {
      border: none;
    }
    &.xs {
      padding: 8px 24px;
    }
    .checkboxListContainer {
      position: static;
      max-height: 224px;
    }
    .labelName {
      font-size: 13px;
    }
  }
}

.pageBlock {
  position: relative;
}

.pageBlock + .pageBlock {
  margin-top: 8px;
}

.partsListGroup.disabled {
  border-color: rgba(68, 68, 68, 0.16) !important;
  pointer-events: none;
}

.partsListGroup:hover {
  border: 1px solid rgb(43 43 43 / 54%);
} 

.partsListGroup {
  list-style: none;
  border: 1px solid rgb(43 43 43 / 34%);
  padding: 8px 16px;
  border-radius: 5px;
  height: 80px;
  overflow: auto;
  font-size: 13px;
  &.hasError {
    border-color: #f44336;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .captionPart {
    display: flex;
    align-items: center;
    .btnPart {
      flex-shrink: 0;
    }
  }
  li + li {
    margin-top: 5px;
  }
  .btnPart {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 10px;
    }
    svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
}

.pageFilter {
  .MuiOutlinedInput-root {
    background-color: #fff;
    max-width: 240px;
  }
}

.boxHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
  z-index: 9;
  &:hover {
    opacity: 1;
  }
  & > *:not(.contextMenu) {
    display: none;
  }
  .chartHeadingOptions {
    width: 100%;
    order: 3;
    justify-content: center;
    flex-wrap: wrap;
    .timeTypeButtons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

//mobile
@media (min-width: $screen-sm) {
  .mainContainer {
    //width: 540px;
  }
  .authFromBlock {
    padding: 32px;
  }
}

//mobile
@media (min-width: $screen-lg) {
  .respModal {
    .MuiDialog-paperScrollPaper {
      display: flex;
      max-height: unset;
      height: calc(100% - 64px);
    }
    &.autoHeight {
      .MuiDialog-paperScrollPaper {
        height: auto;
        margin: auto;
      }
    }
    .logsModal {
      .MuiDialog-paperWidthSm {
        width: 100%;
        padding: 32px;
        max-width: 1012px !important;
        box-shadow: 0 4px 16px 0 rgba(52, 52, 52, 0.24);
        border-radius: 6px;
    }}
    .MuiDialog-scrollPaper {
      align-items: flex-start;
      //overflow-y: auto;
    }
    .checkboxListHolder {
      flex-grow: 1;
      height: auto;
    }
    .checkboxListContainer {
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
    }
    .MuiDialog-paper {
      margin: 32px;
    }
    .MuiDialog-paperWidthSm {
      padding: 24px;
    }
    .checkboxListContainer {
      overflow-x: hidden;
    }
    .btnHolder {
      &.fixedWidthBtn {
        flex-wrap: nowrap;
        display: flex;
        justify-content: flex-end;
        button {
          width: 100px;
          // order: unset;
          // margin-right: 15px;
        }
        button + button {
          margin-right: 15px;
          margin-top: 0;
        }
        .MuiButton-containedPrimary {
          order: 2;
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0;
        }
      }
    }
  }

  .mainContainer {
    padding: 0 30px;
  }
  .mainHeader {
    padding: 10px 30px;
    .mainMenu {
      display: flex;
    }
    .userInfoHolder {
      margin-left: auto;
      .desktop {
        display: flex;
      }
      .mobile {
        display: none;
      }
    }
    .mobileDrawer {
      display: none;
    }
  }
  .mainLayout {
    .footerWrapper {
      flex-wrap: nowrap;
      & > * {
        width: 33.333%;
        &:first-child {
          justify-content: flex-start;
        }
      }
      .copyright {
        padding-bottom: 0;
        text-align: right;
      }
      .langDropdownHolder {
        padding-bottom: 16px;
      }
    }
  }
  .pageBtnSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .part {
      display: block;
      order: unset;
      width: 40%;
      &.centered {
        margin-bottom: 0;
        width: 20%;
        text-align: center;
        button {
          overflow: visible;
          font-weight: 600;
          text-transform: uppercase;
          color: rgba(68, 68, 68, 0.88);
          background-color: transparent !important;
          transition: color 0.3s ease;
          &:hover {
            color: #006ae7;
          }
        }
        
      }
      &.end {
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }
    }
    .part + .part {
      margin-top: 0;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .item {
        display: block;
        width: auto;
      }
      .item + .item {
        margin-left: 15px;
        margin-top: 0;
      }
    }
    .unitChangePopover {
      min-width: 100px !important;
    }
  }
  .boxHeader {
    flex-wrap: nowrap;
    .chartHeadingOptions {
      width: auto;
      order: unset;
      margin-right: 15px;
      margin-left: auto;
      .timeTypeButtons {
        flex-wrap: nowrap;
      }
    }
  }
  .scrollContentModal {
    .halfBlockContainer {
      display: flex;
    }
    .halfBlock {
      width: 50%;
    }
  }
}


//mobile
@media (max-width: $screen-med) {
  .pageBtnSection {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .part {
      display: block;
      order: 2;
      &.centered {
        
        margin-bottom: 0;
        text-align: center;
        p {
          display: block;
          overflow: visible;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 600;
          text-transform: uppercase;
          color: rgba(68, 68, 68, 0.88);
        }
      }
      &.end {
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }
    .part + .part {
      margin-top: 0;
    }
    .btnGroup {
      align-items: center;
      flex-wrap: wrap;
      .item {
        display: flex;
      }
      .item + .item {
        margin-top: 15px;
      }

    }
    .unitsDateRange {
      margin-top: 15px;
    }
  }
  .hideTitleRefreshBtn {
    display: none;
  }
}

@media (min-width: 480px) {
  .hideTitleRefreshBtn {
    display: block;
  }
}

//tablet
@media (min-width: $screen-med) {
  .pageBtnSection {
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    .part {
      order: 2;
      &.centered {
        p {
          display: block;
          overflow: visible;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 600;
          text-transform: uppercase;
          color: rgba(68, 68, 68, 0.88);
        }
      }
     
      &.end {
        justify-content: flex-end;
        flex-wrap: wrap;
        .optionsSwitch {
          min-width: 200px;
        }
      }


    }
    .part + .part {
      margin-top: 0;
    }
    .btnGroup {
      align-items: center;

      .item {
        display: flex;
      }
    }
    .unitsDateRange {
      margin-top: 15px;
      min-width: 200px;
    }
    .jobsDateRange {
      min-width: 200px;
    }
  }
}
.CompanySettingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.CompanySettingsTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #323232;
}

.CompanySettingsSignOutBtn {
  color: #EC3025;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.companySettingsCard {
  // padding: 20px;
  // background-color: #ffffff;
  // border: 1px solid #F2F2F2;
  // border-radius: 12px;
  // width: 556px;
  // margin-bottom: 18px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  z-index: 100;
}
.companySettingsCardTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #B7B7B7;
  margin-bottom: 20px;
}
.companySettingsCardUserName {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #323232;
  margin-bottom: 8px;
}
.companySettingsCardJobName {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6A6A6A;
  margin-bottom: 12px;
}
.userIcon {
  width: 52px;
  height: 52px;
}
.companySettingsCardBtn {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2C68DF;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 12px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marginLeftAuto {
  margin-left: auto;
}
.mr20px {
  margin-right: 20px;
}
.mb40px {
  margin-bottom: 40px;
}
.mb14px {
  margin-bottom: 14px;
}
.companySettingsCardTimeZone {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #6A6A6A;
  margin-bottom: 12px;
}
.companySettingsMainImg {
  width: 94px;
  height: 52px;
}
.companySettingsCheckboxTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(68, 68, 68, 0.88);
}
.companySettingsCheckboxSubTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(68, 68, 68, 0.88);
  margin-left: 12px;
}
.companySettingsSelectorTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(68, 68, 68, 0.88);
}
.companySettingsQtySelector {
  width: 84px;
  margin-left: 12px;
  color: rgba(68, 68, 68, 0.88);
}
.companySettingsPeriodSelector {
  width: 100px;
  margin-left: 12px;
  color: rgba(68, 68, 68, 0.88);
}
.showOnlyTitle {
  display: none;
}




//tablet
@media (min-width: $screen-lg) {
  .mainContainer {
    .partEndBlock {
        display: flex;
        align-items: center;
      }
      .unitsDateRange {
        margin-left: 15px;
        margin-top: 0;
      }
      .jobsDateRange {
        margin-left: 15px;
      }
      .showOnlyTitle {
        display: block;
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(68, 68, 68, 0.88);
        font-size: 13px;
        margin-left: 30px;
        white-space: nowrap;
      }
  }
}

//desktop
@media (min-width: $screen-xl) {
  .mainContainer {
    //width: 1140px;
  }
  .videoHolder {
    video {
      top: 0;
    }
  }
}
@media (max-width: 700px) {
  .centeredMobile {
    position: absolute;
    top: 105px;
    left: 50%;
    transform: translateX(-50%);
  }
  .titleLimitSymbols {
    max-width: 200px; 
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
