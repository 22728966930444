//.highcharts-legend-item {
//  text {
//    fill: #ff0000;
//    font-weight: 300;
//  }
//}

.fill {
  display: block;
  overflow: hidden!important;
  position: relative;
  border: 1px solid #fff;
  margin: -16px;
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    background: #fff;
    height: 1px;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    background: #fff;
    height: 1px;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  canvas {
    display: block;
    width: calc(100% + 2px)!important;
    left: -1px;
  }
}