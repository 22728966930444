.predMainLayout {
  font-size: 13px;
  .mainFooter {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .mainHeader {
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    border-bottom: 1px solid #f2f2f2;
  }
  .main {
    padding: 77px 0 0 0;
    min-height: calc(100vh - 87px);
  }
  .mainAside,
  .secondaryAside {
    background: #fff;
    position: fixed;
    top: 0;
    padding-top: 64px;
    height: 100%;
    bottom: 0;
    z-index: 98;
    padding-bottom: 68px;
  }
  .mainAside {
    width: 265px;
    left: 0;
    border-right: 1px solid #f2f2f2;
    display: none;
  }
  .secondaryAside {
    width: 234px;
    right: 0;
    border-left: 1px solid #f2f2f2;
    display: none;
  }
  .pmPageBlockTitle {
    display: none;
  }
  .pmSubHeader {
    min-height: 63px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 0;
    .title {
      font-weight: 600;
      font-size: 17px;
      color: $primaryColor;
    }
  }
}

@media (min-width: $screen-sm) {

}

@media (min-width: $screen-md) {
  .predMainLayout {
    .main {
      padding: 69px 0 0 250px;
      min-height: calc(100vh - 69px);
    }
    .mainAside {
      display: block;
    }
  }
}

@media (min-width: $screen-lg) {

}

@media (min-width: $screen-xl) {
  .predMainLayout {
    .main {
      padding: 69px 234px 0 250px;
    }
    .secondaryAside {
      display: block;
    }
    .pmPageBlockTitle {
      display: block;
    }
    .pmSubHeader {
      display: none;
    }
  }
}
