.Toastify__toast-container {
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
  min-width: 320px;
  width: fit-content;

  .Toastify__toast {
    border-radius: 5px;

    .Toastify__toast-body {
      padding: 10px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    .Toastify__close-button {
      outline: none;
    }
  }
}

.Toastify__toast {
  font-family: $fontFamily;
  font-size: 13px;
  font-weight: 400;
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
  background: $successColor;
}
.Toastify__toast--warning {
  background: $warningColorToaster;
}
.Toastify__toast--error {
  background: $errorColor;
}
