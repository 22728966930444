.tableControlsHolder .MuiTablePagination-caption {
    font-size: 13px;
    color: #969696;
}

.tableControlsHolder .MuiTablePagination-select {
    height: auto;
    font-size: 13px;
    color: #969696;
}
