button {
  &:focus {
    outline: none;
  }
}

.MuiButton-root {
  &.Mui-disabled {
    .MuiButton-startIcon {
      opacity: .3;
    }
  }
}

.btnHolder {
  &.twoBtn {
    .part {
    }
    .part + .part {
      margin-top: 15px;
    }
  }
  .part {
    position: relative;
    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }
  &.fullWidthMobile {
    display: flex;
    flex-wrap: wrap;
    .part {
      width: 100%;
      text-align: center;
      order: 1;
      margin-top: 16px;
    }
    .part + .part {
      order: 2;
      margin-top: 0;
    }
  }
  &.end {
    display: flex;
    justify-content: flex-end;
    // & > * + * {
    //   margin-left: 15px;
    // }
  }
  &.center {
    display: flex;
    justify-content: center !important;
    @media (min-width: 480px) {
      justify-content: flex-end !important;
    }
  }
  &.justified {
    display: flex;
    justify-content: space-between;
    & > * + * {
      margin-left: 12px;
    }
  }
  &.fixedWidthBtn {
    button {
      width: 121px;
      margin-right: 15px;
    }
  }
  &.fixedWidthBtnWithoutMargin {
    button {
      width: 121px;
    }
  }
}

.refreshButton {
  position: absolute !important;
  top: 8px;
  right: -40px;
}
@media (min-width: 520px) {
  .refreshButton {
    right: 0px;
  }
}

@media (max-width: 520px) {
  .refreshButtonLabel {
    display: none;
  }
}

[class^='btn'] {
  &.hasIco {
    display: flex;
    align-items: center;

    &.right {
      .ico {
        margin-left: 4px;
      }
    }

    &.left {
      .ico {
        margin-right: 4px;
      }
    }
  }
}

.btnPrimary {
  display: inline-flex;
  padding: 10px 32px;
  font-size: 13px;
  line-height: 1.3;
  color: #fff;
  background: $primaryColor;
  text-align: center;
  font-weight: 600;
  border: 1px solid $primaryColor;
  transition: .5s;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  position: relative;
  border-radius: 24px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.3px;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &:active {
    text-decoration: none;
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #c4c4c4;
    border-color: #c4c4c4;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.loading {
    color: rgba(0, 0, 0, 0) !important;

    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border: 2px solid #fff;
      border-left-color: transparent;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: -7px;
      animation-duration: .8s;
      animation-iteration-count: infinite;
      animation-name: loader;
      animation-timing-function: linear;
    }
  }

  &.fullWidth {
    width: 100px;
  }
}

.btnSecondary {
  display: inline-flex;
  padding: 10px 32px;
  font-size: 13px;
  line-height: 1.3;
  color: rgba(68, 68, 68, 0.88);
  background: transparent;
  text-align: center;
  font-weight: 600;
  border: 1px solid rgba(68, 68, 68, 0.24);
  transition: .5s;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  position: relative;
  border-radius: 24px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.3px;

  &:hover {
    text-decoration: none;
    color: rgba(68, 68, 68, 0.88);
  }

  &:active {
    text-decoration: none;
    //color: #fff;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #c4c4c4;
    border-color: #c4c4c4;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.loading {
    color: rgba(0, 0, 0, 0) !important;

    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border: 2px solid #fff;
      border-left-color: transparent;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: -7px;
      animation-duration: .8s;
      animation-iteration-count: infinite;
      animation-name: loader;
      animation-timing-function: linear;
    }
  }

  &.fullWidth {
    width: 100px;
  }
}

.dropDownBtn {
  &.icoOnly {
    .MuiButton-startIcon {
      margin: 0;
    }
  }
}

.MuiButton-label {
  .hasMaxWidth {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
}

.select-job-button {
  color: rgba(68, 68, 68, 0.88)!important;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//mobile
@media (min-width: $screen-sm) {
  .btnHolder {
    &.twoBtn {
      display: flex;
      .part {
        width: calc(50% - 15px);
      }
      .part + .part {
        margin-top: 0;
        margin-left: 30px;
      }
    }
    &.fullWidthMobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.end {
        justify-content: flex-end;
      }
      .part {
        width: auto;
        text-align: center;
        order: 1;
        margin-top: 0;
      }
      .part + .part {
        order: 2;
      }
    }
  }
}

//mobile
@media (min-width: $screen-md) {

}

//tablet
@media (min-width: $screen-lg) {

}

//desktop
@media (min-width: $screen-xl) {

}
