.tabsLike {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 16px;
  .tabsLikeItem {
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    color: rgba(68, 68, 68, 0.56);
    //font-weight: 600;
    &:hover {
      //text-decoration: underline;
    }
    &.active {
      color: $primaryColor;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .tabsLikeItem + .tabsLikeItem {
    margin-left: 16px;
  }
}

@media (max-width: $screen-med) {
  .tabsLike {
    display: none;
  }
}
