h2 {
  font-size: 17px;
  color: rgba(68, 68, 68, 0.88);
  font-weight: 600;
}

h3 {
  font-size: 13px;
  letter-spacing: 0.3px;
  color: rgba(68, 68, 68, 0.56);
  margin-bottom: 16px;
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-size-normal {
  font-size: $fontSize;
}

.text-mute {
  font-size: $fontSizeMute;
}

.text-lead {
  font-size: $fontSizeLead;
}

.linkPrimary {
  color: $primaryColor!important;
  &:hover {
    color: darken($primaryColor, 5%)!important;
    text-decoration: underline;
  }
}

.linkGray {
  color: rgba(68, 68, 68, 0.88)!important;
  &:hover {
    color: darken(rgba(68, 68, 68, 0.88), 5%)!important;
    text-decoration: underline;
  }
}

.text-bold {
  font-weight: bold!important;
}

.text-semi-bold {
  font-weight: 600!important;
}

.text-right {
  text-align: right!important;
}
.deletedDepartment {
  opacity: 0.65; 
  cursor: 'pointer';
}