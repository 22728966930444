@import "pm-layout-layout";

.formTabs {
  .PrivateTabIndicator-colorPrimary-3 {
    background-color: rgba(0, 0, 0, 0.54);
  }
  margin-top: 12px;
  .MuiTabs-root {
    min-height: 32px;
  }
  .MuiTab-root {
    padding: 0 6px;
    min-width: auto;
    text-transform: none;
    min-height: auto;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: rgba(0, 0, 0, 0.54);
    font-weight: bold;
  }
}

.asideNav {
  .MuiButton-label {
    justify-content: flex-start;
  }
  .MuiButton-text {
    border-radius: 0;
  }
}
.loaderHolderTemplates {
  position: absolute;
    width: 100%;
    height: 100%;
    left: 23%;
    top: 5%;
}
.predMainLayout {
  .subNavHolder {
    padding: 15px 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  .asideNavHolder {
    padding: 15px 10px 135px 10px;
    &.secondary {
      padding: 15px 0;
      .asideNavItemHolder + .asideNavItemHolder {
        //margin-top: 0;
      }
    }
  }
  .asideNav {
    list-style: none;
  }
  .mainAsideSubItems {
    padding-left: 30px;
  }
  .asideNavItem {
    justify-content: flex-start;
    border-radius: 32px;
    margin-bottom: 5px;
    &:hover {
      background-color: #f5faff;
      color: $primaryColor;
    }
    &.active {
      background-color: #f5faff;
      color: $primaryColor;
    }
  }
  .asideNavItemHolder + .asideNavItemHolder {
    // margin-top: 5px;
  }
  .pmPageHolder {
    padding: 0 0 0 30px;
  }
  .pmPageBlock {
    padding: 15px 0;
  }
  .pmPageBlock + .pmPageBlock {
    //border-top: 1px solid #f2f2f2;
  }
  .pmPageBlockTitle {
    font-weight: 600;
    font-size: 17px;
    color: $primaryColor;
    margin-bottom: 15px;
  }
  .ucSubTitleBlock {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
  }
  .ucSubTitle {
    color: #757575;
    font-weight: 600;
    cursor: pointer;
    padding-bottom: 5px;
  }
  .activeSubTitle {
    color: #006ae7 !important;
    border-bottom: 1px solid #006ae7 !important;
    // background-color: #fff;
  }
  .pmTableHolder {
    overflow-x: auto;
    overflow-y: visible;
    position: relative;
    padding: 0 5px;
    margin: 0 -5px;
    .loaderHolder {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .pmTabs {
    &.MuiTabs-root {
      min-height: 35px;
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #f2f2f2;
      }
    }
    .MuiTabs-indicator {
      z-index: 1;
      border-bottom: 1px solid #006ae7 !important;
    }
    .MuiTab-root {
      min-width: 0;
      padding: 0;
      padding-bottom: 10px;
      font-size: 13px;
      text-transform: none;
      line-height: 22px;
      color: #969696;
      min-height: unset;
      &.Mui-selected {
        color: $primaryColor;
        border-bottom: 1px solid #006ae7 !important;
      }
      &:hover {
        color: $primaryColor;
      }
    }
    .MuiTab-root + .MuiTab-root {
      margin-left: 30px;
    }
  }
  .subTitleWithFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .filter {
    margin-right: 10px;
  }
}

.searchBar {
  margin-bottom: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.searchBarMessage {
  color: #757575;
  font-weight: bold;
}

.searchHolder {
  margin-left: 4px;
}

.add {
  position: relative;
  padding-left: 21px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background-image: url("#{$imPathPm}/pred-main/plus_square_grey.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.pmTable {
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #757575;
    text-align: left;
    padding: 0 10px 10px;
    vertical-align: baseline;
    white-space: nowrap;
    cursor: pointer;
    &.hasArrow {
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        background: url("#{$imPathPm}/arrow.svg") no-repeat;
        background-size: contain;
        background-position: center;
        height: 18px;
        width: 18px;
        opacity: 0.56;
        margin-top: -3px;
        vertical-align: middle;
      }

      &.up {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    &:hover {
      &.hasArrow {
        &:before {
          opacity: 0.88;
        }
      }
    }
  }
  td {
    padding: 10px;
    background-color: #fff;
    vertical-align: middle;
    color: #757575;
    border-collapse: collapse;
  }
  tr {
    &:hover {
      td {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
    }
  }
  .checkBoxCell {
    padding: 0 !important;
    width: 42px;
    & + th,
    & + td {
      padding-left: 0;
      text-align: left;
    }
  }
  .textFieldCell {
    padding: 0 !important;
  }
  .pr10 {
    padding: 0 10px 0 0;
    // width: 240px;
    // min-width: 240px;
  }
  .selectField {
    .MuiFormControl-root {
      min-width: 150px;
      max-width: 160px;
    }
  }
  .MuiFormControl-root {
    max-width: 150px;
    .MuiOutlinedInput-input {
      height: auto;
      padding: 5px;
      background-color: #fff;
    }
  }
  .MuiInputBase-root.Mui-disabled {
    .MuiOutlinedInput-input {
      color: #cacaca;
    }
  }
  .statusItem {
    display: block;
    text-align: right;
    &.on {
      color: #6cc147;
    }
    &.off {
      color: grey;
    }
    &.open {
      color: #006ae7;
    }
    &.overdue {
      color: #DF1A1A;
    }
    &.upcoming {
      color: #C7C735;
    }
  }
  .textFieldCellDue {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
      background-color: #fff;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .slide-in-from-left {
    animation: slideInFromLeft 0.3s ease-in-out;
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .slide-in-from-top {
    animation: slideInFromTop 0.3s ease-in-out;
  }

  .operStatus {
    font-size: 150px;
    text-align: right;
  }
  .statusWithCaption {
    display: flex;
    align-items: center;
    .statusItem {
      margin-right: 10px;
    }
  }
  .hasInnerRowIcon {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 5px;
    }
  }
  .dbl {
    .hasInnerRowIcon {
      padding-left: 15px;
    }
  }
  .activitiesInnerTableHolder {
    &:hover {
      td {
        background: inherit;
      }
    }
    tr {
      &:hover {
        td {
          background: inherit;
        }
      }
    }
    .activitiesInnerTableContent {
      padding: 0;
    }
    .activitiesInnerTable {
      width: 100%;
      border-collapse: collapse;
      th {
        padding: 5px 15px;
        background-color: #ededed;
      }
      td {
        border: none;
        padding: 5px;
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      tr {
        & > * {
          &:first-child {
            //padding-left: 42px;
          }
        }
        &:nth-child(even) {
          td {
            background-color: #f3f4f7;
          }
        }
        &:nth-child(odd) {
          td {
            background-color: #f7f9fa;
          }
        }
      }
    }
  }
  .qntTextField {
    max-width: 60px;
  }
  .addActivityBtn {
    position: relative;
    padding: 5px;
    padding-left: 30px;
    transition: .2s;
    border-radius: 15px;
    width: 200px;
    text-align: center;
    .amPlIcon {
      position: absolute;
      left: 50px;
      top: 50%;
      margin-top: -10px;
    }
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .activitiesItem {
    position: relative;
    color: #757575;
    white-space: nowrap;
    display: inline-block;
    padding: 4px;
    padding-left: 31px;
    padding-right: 22px;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -8px;
      background-image: url("#{$imPathPm}/pred-main/list_check.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:after {
      content: "";
      display: block;
      width: 7px;
      height: 4px;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -1px;
      background-image: url("#{$imPathPm}/pred-main/ascending.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  tr + tr {
    td {
      border-top: 1px solid #f2f2f2;
    }
  }
  tr {
    td {
      &:first-child {
        border-left: 1px solid #f2f2f2;
      }
      &:last-child {
        border-right: 1px solid #f2f2f2;
      }
    }
    &:first-child {
      td {
        border-top: 1px solid #f2f2f2;
      }
    }
    &:last-child {
      td {
        border-bottom: 1px solid #f2f2f2;
      }
    }
  }
  .btnCell {
    padding: 0;
    padding-right: 6px;
    width: 15px;
    text-align: right;
  }
  .textBtnCell {
    padding: 0;
  }
  .activitiesRow {
    .activitiesRowContent {
      padding: 0;
      &:hover {
        background: rgb(255, 255, 255) !important; 
      }
    }
    .activitiesTable {
      width: 100%;
      border-collapse: collapse;
      th {
        background: #ededed;
        padding: 5px;
      }
      td {
        border: none;
        padding: 5px;
        background: #f7f8fa;
      }
      tr {
        th,
        td {
          &:first-child {
            padding-left: 10px;
            background: none;
          }
        }
        &:nth-child(even) {
          td {
            background: #f3f4f7;
          }
        }
      }
    }
  }
  &.compActivityTable {
    .MuiIconButton-root {
      padding: 4px;
    }
    th,
    td {
    }
    tr {
      &:first-child {
        td {
          border-top: 0;
        }
      }
      th,
      td {
        padding-left: 5px;
        padding-right: 5px;
        border-left: 0;
        border-right: 0;
        &:first-child {
          padding-left: 0;
          white-space: nowrap;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .checkBoxCell {
        width: 28px;
      }
    }
  }
}

.filterCounter {
  background-color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 32px;
  font-size: 14px;
  display: block;
  text-align: center;
  color: #006ae7;
}

.view-scroll {
  overflow: auto !important;
  padding-right: 0px !important;
}

.partsSearchInput {
  min-width: 250px !important;
  margin-top: 8px !important;
}

.filterMenuItem {
  &.MuiMenuItem-root {
    font-size: 13px;
    padding: 4px 8px;
    .MuiIconButton-root {
      padding: 4px;
    }
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
    &.hasDeleteBtn {
      justify-content: space-between;
      .MuiIconButton-root {
        margin-left: 5px;
        flex-shrink: 0;
      }
    }
  }
}

.dateRangePicker .MuiOutlinedInput-input {
  cursor: pointer !important;
}
.dateRangePickerIcon {
  cursor: pointer !important;
  color: rgba(68, 68, 68, 0.88);
}

.autocompleteBox {
  margin-top: 5px;
  margin-bottom: 10px;
}

.autocomplete .MuiAutocomplete-clearIndicator {
  display: none;
}

.MuiAutocomplete-listbox li[data-option-index="0"] {
  background-color: rgba(0, 0, 0, 0.08);
}

.autocomplete .MuiOutlinedInput-input {
  color: rgba(68, 68, 68, 0.88);
  height: 0;
  font-size: 13px;
  cursor: pointer;
  z-index: 1;
  transform: translate(-8px, 0px) scale(1);
}

.autocomplete .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(8px, 12px) scale(1);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: rgba(68, 68, 68, 0.56);
  font-weight: 600;
}

.autocomplete input::placeholder {
  color: rgba(68, 68, 68, 0.88);
  opacity: 1;
}

.autocomplete input:focus::placeholder {
  color: rgb(165, 165, 165);
}

.MuiAutocomplete-paper {
  font-size: 13px !important;
}

.autocomplete .MuiAutocomplete-inputRoot {
  cursor: pointer !important;
}

.autocompletePartActivity .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding-left: 6px;
  padding-top: 0;
  padding-bottom: 0;
  height: 18px;
}

.autocompleteActivityModal .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding-left: 6px;
  padding-top: 0;
  padding-bottom: 0;
  height: 18px;
}

.autocompleteActivityModal .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.filterMenuItemDisabled {
    font-size: 13px;
    padding: 4px 8px;
    opacity: 0.5;
}

.addBoxMenuItem {
  &.MuiMenuItem-root {
    font-size: 13px;
    width: auto;
    font-size: 13px;
    padding: 4px 8px;
  }
}

.MuiMenuItem-root {
  font-size: 13px !important;
}

.addNewIntegrationItem {
  &.MuiMenuItem-root {
    font-size: 13px;
    width: auto;
    min-width: 110px;
    font-size: 13px;
    padding: 4px 8px;
  }
}


.hasDeleteOnPlaceHolder {
  .MuiInputBase-input {
    position: relative;
    .MuiIconButton-root {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      z-index: 99;
    }
  }
  .MuiSelect-outlined {
    &.MuiSelect-outlined {
      padding-right: 62px;
    }
  }
}

.iconBtnSm {
  &.MuiIconButton-root {
    padding: 4px;
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
}

.companyInfoItemHolder {
  display: flex;
  align-items: center;
  .imgHolder {
    position: relative;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .companyMainImg {
    max-width: 100%;
    display: block;
    object-fit: contain;
    width: 120px;
    height: 120px;
  }
  .companyBtnHolder {
    position: absolute;
    bottom: -10px;
    right: -10px;
    .MuiIconButton-root {
      padding: 8px;
      background: #fff;
      box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: $primaryColor;
      }
    }
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
  .captionHolder {
    padding-left: 15px;
    .title {
      color: #5b5b5b;
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
    }
    .subTitle {
      font-size: 13px;
      line-height: 19px;
      color: #757575;
      margin-top: 5px;
    }
  }
}

.textFieldTableHolder {
  overflow-y: auto;
  .textFieldTable {
    width: 100%;
    td {
      vertical-align: middle;
      padding: 7.5px;
      //max-width: 185px;
      &:not(.btnCell) {
        min-width: 65px;
      }
      &.nameCell {
        width: 200px;
      }
    }
    tr {
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .btnCell {
      padding: 0;
      width: 15px;
      .tfgItem {
        max-width: 48px;
      }
      .btnItem {
        .MuiSvgIcon-root {
          color: rgb(59, 70, 99);
        }
        .Mui-disabled {
          .MuiSvgIcon-root {
            color: rgba(59, 70, 99, .56);
          }
        }
      }
    }
    .qntCell {
      max-width: 65px;
      width: 65px;
    }
  }
}

.editTextOnClick {
  cursor: pointer;
  width: 100%;
  .captionPart {
    justify-content: space-between;
  }
}

.pmPopover {
  &.MuiPopover-root {
    .MuiPopover-paper {
      box-shadow: 0 0px 0.4px rgba(0, 0, 0, 0.01);
      min-width: 200px;
    }
  }
}

.emptyInfoHolder {
  text-align: center;
  padding: 30px 0;
  color: #757575;
  .title {
    font-size: 17px;
    margin-bottom: 15px;
  }
  .text {

  }
}

.datePickerWithLabel {
  position: relative;
  .labelLike {
    position: absolute;
    color: rgba(68, 68, 68, 0.56);
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    transform: translate(14px, -2px) scale(0.75);
    background: #fff;
    left: -18px;
    display: inline-block;
    padding: 0 5px;
    top: -2px;
    z-index: 2;
  }
}

.activitiesFilterHolder {
  max-width: 290px;
  padding: 15px;
  .filterHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .pmPageBlockTitle {
      font-weight: 600;
      font-size: 17px;
      color: #5b5b5b;
    }
    a {
      font-size: 15px;
      color: #757575;
      text-decoration: none;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 10px;
}
}

.react-datepicker-popper {
  z-index: 2;
}

.replaceModalDatepicker {
  .react-datepicker {
    position: absolute !important;
    bottom: -80px !important;
    left: -136px;
    width: 328px;
  }
  .react-datepicker__triangle {
    display: none;
  }
}

.replaceModalDatepickerContainer {
    width: 350px;
    position: absolute;
    z-index: 2;
    top: 131px;
    right: 0px;
}
.datePickerCalendarIcon {
  position: absolute;
  padding: 6px;
  box-sizing: content-box;
  right: 0;
  color: rgba(43, 43, 43, 0.54);
  cursor: pointer;
}
.replaceModalNewComponentBtn {
  margin-right: -8px !important;
}
.replaceModalNewComponentIcon {
  position: absolute;
  width: 20px !important;
  padding-right: 1px !important;
  box-sizing: content-box;
  right: 5;
  color: rgba(43, 43, 43, 0.54);
  cursor: pointer;
}

.formControlDatePicker {
  display: block;
  width: 100%;
  font-size: 13px;
  padding: 12px;
  border-radius: 6px;
  height: 36px;
  color: rgba(68, 68, 68, 0.88);
  background: none;
  outline: none;
  box-shadow: none;
  border: 1px solid rgb(43 43 43 / 34%);
  margin-top: 4px;
  margin-bottom: 8px;
  // &:hover {
  //   border: 1px solid rgba(0, 0, 0, 0.26);
  // }
  &:disabled {
    border: 1px solid rgba(68, 68, 68, 0.06);
  }
  &:hover {
    border: 1px solid rgb(43 43 43 / 54%);
  }
}

.datePickerWithLabel {
  &.hasError {
    .formControlDatePicker {
      border-color: #e72222;
    }
    .labelLike {
      color: #e72222;
    }
  }
}

.react-datepicker-wrapper {
  display: block;
  .react-datepicker__input-container {
    display: block;
  }
}

@media (max-width: 480px) {
  .react-datepicker__month-container {
    width: 240px; 
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 20px !important; 
    height: 20px !important; 
    line-height: 25px !important; 
  }
}

.equipmentSubMenu {
  .subMenuIcon {
    margin-right: 2px;
    margin-top: 1px;
    margin-left: 7px;
    align-items: center;
    display: flex;
    
  }
  .subMenuIcon > * {
    font-size: 1.2rem;
    }
  .reverseSwitchHolder {
    padding: 5px 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .menuItem {
    position: relative;
    padding-left: 37px;
    font-size: 13px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      top: 50%;
      left: 16px;
      margin-top: -8px;
    }
    &.add {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/plus_square.svg");
      }
    }
    &.edit {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/edit.svg");
      }
    }
    &.duplicate {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/copy.svg");
      }
    }
    &.changeDepartment {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/low_priority.svg");
      }
    }
    &.replace {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/replace-icon.svg");
      }
    }
    &.uninstall {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/uninstall.svg");
      }
    }
    &.change {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/change.svg");
      }
    }
    &.delete {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/trash_full.svg");
      }
    }
    &.mark {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/mark-as-completed.svg");
      }
    }
    &.assign {
      &:before {
        background-image: url("#{$imPathPm}/pred-main/assign-person.svg");
      }
    }
  }
}


.activitiesTaskTableHolder {
  overflow-x: auto;
}

// .MuiFormLabel-root {
//   color: rgba(0, 0, 0, 0.88) !important;
// }

.activitiesTaskTable {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  .checkBoxLikeHolder {
    width: 26px;
  }
  .checkBoxCell {
    width: 45px;
  }
  .taskNameHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eeeded;
    padding: 7px 8px;
    font-size: 13px;
    height: 36px;
    border-radius: 6px;
    .icoHolder {
      display: block;
      width: 16px;
      height: 16px;
      background-image: url("#{$imPathPm}/pred-main/infoicon.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 7.5px;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 0px 0px 0px 3px;
  }
  th {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #757575;
    text-align: left;
    padding: 0 7.5px 2.5px;
    vertical-align: baseline;
    white-space: nowrap;
  }
  tr {
    th {
      &:first-child {
        padding-left: 0;
      }
    }
    td {
      vertical-align: middle;
      padding: 8px 7.5px;
      position: relative;
      &:not(.checkBoxCell) {
        min-width: 70px;
      }
      &:first-child {
        //border-radius: 8px 0 0 8px;
        padding-left: 0;
      }
      //&:last-child {
      //  border-radius: 0 8px 8px 0;
      //}
    }
  }
  .emptyCellHolder {
    text-align: center;
    color: #757575;
    padding: 15px 0;
  }
  &.pageTable {
    .qntCell {
      width: 83px;
    }
    .taskNameHolder {
      height: 29px;
      background-color: transparent;
      border-color: transparent;
      padding-left: 0;
      display: block;
      cursor: default;
      .infoHolder {
        display: none;
      }
    }
    .MuiFormControl-root {
      .MuiOutlinedInput-input {
        height: auto;
        padding: 5px;
        background-color: #fff;
      }
      .MuiInputBase-root {
        &.Mui-disabled {
          .MuiOutlinedInput-input {
            background-color: transparent;
            border-color: transparent;
            padding: 0;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: transparent!important;
          }
          .MuiSelect-icon {
            display: none;
          }
        }
      }
    }
    tr + tr {
      td {
        //border-top: 2.5px solid #fff;

      }
    }
    th {
      padding: 0 2.5px 15px;
    }
    tr {
      &:nth-child(odd) {
        td {
          background: #f3f4f7!important;
        }
      }
      &:nth-child(even) {
        td {
          background: #f7f9fa!important;
        }
      }
      td {
        padding-top: 2.5px;
        padding-bottom: 2.5px;
        &:first-child {
          border-radius: 0;
          padding-left: 15px;
        }
        &:last-child {
          border-radius: 0;
        }
      }
      th {
        &:first-child {
          padding-left: 15px;
        }
      }
    }
    th {
      padding: 2.5px 7.5px;
      background-color: #ededed;
    }
  }
  .MuiIconButton-root {
    padding: 4px;
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
      color: #a6a6a6;
    }
  }
  &.pageTable {
    tr {
      td {
        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2) {
          width: calc(60% - 150px);
        }
        &:nth-child(3) {
          min-width: 150px;
          width: 150px;
        }
        &:nth-child(4) {
          width: 165px;
        }
        &:nth-child(5) {
          width: 85px;
        }
        &:nth-child(6) {
          width: 45px;
        }
      }
    }
  }
}

.activitiesTaskPopover {
  font-size: 13px;
  .MuiPopover-paper {
    padding: 10px;
    min-width: 240px;
  }
  .title {
    font-weight: bold;
  }
}

.notePopoverBlock {
  .MuiPaper-elevation8 {
    box-shadow: none;
  }
}
.activeNoteBorder .MuiOutlinedInput-notchedOutline {
  border-color: rgb(43 43 43 / 54%) !important;
}

.activityNotePopover {
  width: 400px;
  padding: 0px;
  @media (min-width: 1400px) {
    width: 450px;
  }

  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding-top: 4.5px;
    padding-bottom: 7.5px;
    height: 151px;
    align-items: flex-start;
    display: flex;
    font-size: 18px;
  }
}

.workhoursUpdateModalTitle {
  font-size: 20px;
  font-weight: 600;
  color: rgba(68, 68, 68, 0.88);
}
.workhoursUpdateJobTitle {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #6A6A6A;
}
.workHoursField {
  width: 130px;
}
.workHoursField .MuiOutlinedInput-input {
  cursor: pointer;
}
.workHoursFieldIcon {
  cursor: pointer;
  margin-left: 0px !important;
}

.workHoursField::before {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  z-index: 100;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.workHoursSerialSubTitle {
  font-size: 12px;
  text-align: center;
  color: rgba(68, 68, 68, 0.56);
}

.syncWorkhours {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 100;
  right: 30px;
  top: 6.5px;
}

.none {
  display: none;
}

.workHoursField.sync::before {
  background-image: url("#{$imPathPm}/pred-main/sync.svg");
  width: 18px;
  height: 18px;
}

.workHoursField.dedicated::before {
  background-image: url("#{$imPathPm}/pred-main/sync.svg");
  width: 18px;
  height: 18px;
}

.workHoursField.manual::before {
  background-image: url("#{$imPathPm}/pred-main/touch-app.svg");
}

.workHoursField::before {
  background-image: url("#{$imPathPm}/pred-main/parent.svg");
}

.workhoursWarningBlock {
  display: flex;
  padding: 10px;
  background: #FFFDE7;
  border: 1px solid #F1C219;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 10px;
}

.animatedBlock {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.3s ease forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.state-item-table {
  display: block !important;
  width: 95px !important;
  padding: 0px 15px 0px 6px !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  white-space: nowrap !important;
  text-align: left;
  &:after {
    content: "";
    display: block;
    width: 7px;
    height: 4px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -1px;
    background-image: url("#{$imPathPm}/pred-main/ascending.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.state-item {
  display: block !important;
  width: 95px !important;
  padding: 1px 15px 1px 6px !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  white-space: nowrap !important;
  font-weight: 600 !important;
  text-align: left;
}

.statePopover {
  box-shadow: 0 0px 0.4px rgba(0, 0, 0, 0.2) !important;
  padding: 10px 10px 0px 10px !important;
  margin: 0 !important;
  width: 150px !important;
}
.filterPopover {
  box-shadow: 0 0px 0.4px rgba(0, 0, 0, 0.2) !important;
  padding: 10px 10px 0px 10px !important;
  margin: 0 !important;
  width: 180px !important;
}

.filterCheckbox {
  width: 18px !important;
  height: 18px !important;
  margin-right: 10px !important;
  color: rgba(43, 43, 43, 0.54) !important;
  .MuiSvgIcon-root {
    fill:  rgba(43, 43, 43, 0.54) !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
}

.filterPopoverItem {
  display: flex;
  align-items: center;
}

.fieldReady {
  background-color: #e9f7e7 !important;
  border-color: #48AF8A !important;
  color: #48AF8A !important;
}

.disposed {
  background-color: #f2f2f2 !important;
  border-color: #6a6a6a !important;
  color: #6a6a6a !important;
}

.toRepair {
  background-color: #eae4f9 !important;
  border-color: #8968ea !important;
  color: #8968ea !important;
}

.removed {
  background-color: rgb(252, 210, 210) !important;
  border-color: #ff6c6c !important;
  color: #ff6c6c !important;
}

.toCheck {
  background-color: #fff9f2 !important;
  border-color: #f38b2b !important;
  color: #f38b2b !important;
}
.installed {
  background-color: #eef3fe !important;
  border-color: #376df4 !important;
  color: #376df4 !important;
}

.status-item {
  display: block !important;
  width: 95px !important;
  padding: 1px 1px 1px 1px !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  white-space: nowrap !important;
  font-weight: 600 !important;
  text-align: center;
}

.Successful {
  background-color: #e9f7e7 !important;
  border-color: #48AF8A !important;
  color: #48AF8A !important;
}

.Error {
  background-color: rgb(252, 210, 210) !important;
  border-color: #ff6c6c !important;
  color: #ff6c6c !important;
}

.boldStatusMessage {
  font-weight: 600;
}

// .componentsMenuPopover {
//   box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.06) !important;
//   margin: 0 !important;
//   min-width: 200px !important;
// }

.componentsMenuPopover.MuiPopover-root .MuiPopover-paper {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  min-width: 200px;
}

.componentsMenuItem {
  position: relative !important;
  padding: 6px 8px 6px 37px !important;
  font-size: 13px !important;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    left: 16px;
    margin-top: -8px;
  }
  &.history {
    &:before {
      background-image: url("#{$imPathPm}/pred-main/showHistoryIcon.svg");
    }
  }
  &.duplicate {
    &:before {
      background-image: url("#{$imPathPm}/pred-main/copy.svg");
    }
  }
  &.delete {
    &:before {
      background-image: url("#{$imPathPm}/pred-main/trash_full.svg");
    }
  }
  &.edit {
    &:before {
      background-image: url("#{$imPathPm}/pred-main/edit.svg");
    }
  }
}

.history-popover {
  position: fixed !important;
  padding: 20px;
  right: 0 !important;
  top: 70px !important;
  width: 400px !important;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.06) !important;
}
.history-popover-title {
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
}
.history-popover-uId {
  font-size: 10px;
  font-weight: 600;
}
.history-popover-filter {
  height: 28px !important;
  padding: 12px 12px !important;
}

.flex-line {
  position: relative;
}

.flex-line::before {
  content: "";
  position: absolute;
  top: 100%;
  left: -20px; 
  right: -20px; 
  height: 1px;
  background-color: #F2F2F2;
  z-index: 1;
}

@media (min-width: $screen-md) {
  .predMainLayout {
    .footerWrapper {
      flex-wrap: nowrap;
      .langDropdownHolder {
        padding-bottom: 16px;
      }
    }
    .copyright {
      width: auto;
      padding-bottom: 0;
    }
  }
}

@media (min-width: $screen-large) {
  .predMainLayout {
    .footerWrapper {
      .langDropdownHolder button {
        padding-left: 20px;
      }
    }
  }
}

