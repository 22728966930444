//@import "~bootstrap";
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');
@import "variables";
@import "reset";
@import "colors";
@import "borders";
@import "wrappers";
@import "typography";
@import "spacings";
@import "grid";
@import "buttons";
@import "icons";
@import "tables";
@import "forms";
@import "lists";
@import "chart";
@import "layout";
@import "dnd";
@import "pred-main/pm-layout";

legend {
  width: auto;
  max-width: unset;
}

body {
  overflow-x: hidden;
  font-size: $fontSize;
  font-family: $fontFamily;
  color: rgba(68, 68, 68, 0.88);
  background: #f5f5f6;

  &.menuOpen {
    overflow-y: hidden;
  }
}



.logo {
  display: block;
  width: 220px;
  height: 43px;
  background-image: url("#{$imgPath}/logo-light.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &:before,
  &:after {
    display: none;
  }
  &.full {
    width: 165px;
    height: 116px;
  }
  @media (max-width: 449px) {
    width: 130px;
    margin-right: 0;
  }
}

.theme-dark .logo {
  background-image: url("#{$imgPath}/logo-dark.png");
}

.arrowBackLink {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("#{$imgPath}/arrow-back-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.mainMenu {
  list-style: none;
  align-items: center;
  padding: 0 20px;
  a {
    font-size: 15px;
    font-weight: 600;
    color: rgba(68, 68, 68, 0.56);
    text-decoration: none;
    display: block;

    &.active {
      color: $primaryColor;
    }
    &:hover {
      color: $primaryColor;
    }

  }

  li + li {
    margin-left: 40px;
  }
}

.pageBlockHeadingPart {
  display: flex;
  align-items: center;
  button {
    margin-left: 16px;
    opacity: 0;
    transition: opacity .1s;
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
  .editBtn {
    margin-left: 16px;
    color: #383838;
  }
}

.langDropdownHolder {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  button {
    &:before {
      content: "";

    }
  }

  .dropdown-toggle {
    box-shadow: none;
    outline: none;
    background: none;
    border: none;
    color: rgba(68, 68, 68, 0.56);
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 8px 12px;
    border-radius: 32px;



    &:after {
      display: none;
    }
  }

  .btn-success:not(:disabled):not(.disabled):active:focus,
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    border: none;
    outline: none;
    background-color: rgba(68, 68, 68, 0.05);
    color: rgba(68, 68, 68, 0.56);
  }

  .dropdown-menu {
    box-shadow: 0 4px 16px 0 rgba(52, 52, 52, 0.24);
    background-color: #ffffff;
    border-radius: 7px;
    border: none;
    padding: 16px 0;
    margin-bottom: 16px;
    left: 50% !important;
    width: 160px;
    margin-left: -80px;

    .dropdown-item {
      color: rgba(68, 68, 68, 0.88);
      text-align: center;
      font-size: 15px;
      padding: 9px 48px;
      position: relative;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: transparent;
      color: rgba(68, 68, 68, 0.88);

      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("#{$imgPath}/round-check-24px.svg");
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: rgba(68, 68, 68, 0.03);
    }
  }
}

.optionsDropdown {
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .btn-success:not(:disabled):not(.disabled).active:focus,
  &.show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  &.show > .btn-success.dropdown-toggle {
    border: none;
    outline: none;
    background-color: rgba(68, 68, 68, 0.05);
    color: rgba(68, 68, 68, 0.56);
  }

  .dropdown-toggle {
    box-shadow: none;
    outline: none;
    background: none;
    border: none;
    color: rgba(68, 68, 68, 0.56);
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 8px 12px;
    border-radius: 32px;

    &.userInfo {
      padding: 4px 12px 4px 4px;
    }

    &:active {
      background-color: rgba(68, 68, 68, 0.05);
    }

    &:after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      background-color: rgba(68, 68, 68, 0.05);
    }
  }

  .dropdown-menu {
    margin-top: 8px;
    box-shadow: 0 4px 16px 0 rgba(52, 52, 52, 0.24);
    border: 0;
    padding: 8px 0;
  }

  .dropdown-item {
    color: rgba(68, 68, 68, 0.88);
    font-size: 15px;
    padding: 8px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(68, 68, 68, 0.03);
    }

    &.active {
      font-weight: 600;
      background: none;

      &:hover {
        background-color: rgba(68, 68, 68, 0.03);
      }
    }
  }

  &.checkboxLike {
    .dropdown-item {
      display: block;
      align-items: center;
      padding-left: 48px;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-image: url("#{$imgPath}/check.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 8px;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
      }

      &.active {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &.hasRemoveBtn {
    .dropdown-item {
      padding-right: 56px;

      .removeBtn {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        opacity: 0;
      }

      &:hover {
        .removeBtn {
          opacity: 1;
        }
      }
    }
  }

  &.centered {
    .dropdown-menu {
      width: 480px;
      left: 50% !important;
      top: 100% !important;
      transform: translateX(-50%) !important;
    }
  }

  .removeBtn {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: url("#{$imgPath}/delete.svg") no-repeat;
    background-size: 24px;
    background-position: center;

    &:hover {
      background-color: rgba(68, 68, 68, 0.12);
    }
  }
}

.userInfo {
  display: flex;
  align-items: center;
  .userName {
    font-size: 13px;
    color: rgba(68, 68, 68, 0.88);
    margin-left: 15px;
  }
  .MuiBadge-dot {
    border: 2px solid #fff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4ac530;
    transform: translate(0);
  }
}

.userImg {
  display: block;
  border-radius: 50%;
  &.xs {
    width: 24px;
    height: 24px;
  }
  &.sm {
    width: 32px;
    height: 32px;
  }
  &.md {
    width: 36px;
    height: 36px;
  }
}




.modalTitle {
  font-size: 15px;
    font-weight: 600;
    color: rgba(68, 68, 68, 0.88);
    justify-content: center;
    display: flex;
  &.hasIcon {
    display: flex;
    align-items: center;
    &:before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 4px;
    }
    &.warning {
      &:before {
        background-image: url("./../../../assets/img/round-warning-circle-outline-24px.svg");
      }
    }
    &.error {
      &:before {
        background-image: url("./../../../assets/img/round-play-circle-outline-24px.svg");
      }
    }
  }
}

.deleteItemLabel {
  margin-bottom: 16px;
  @media (min-width: $screen-sm) {
    margin-bottom: 0px;
  }
}

.errorList {
  padding-left: 34px;
  li + li {
    margin-top: 5px;
  }
}

.modalTitleHolder {
  &.hasBackLink {
    position: relative;
    .backLinkHolder {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .modalTitle {
      padding-left: 36px;
      padding-right: 36px;
    }
    .backLink {
      position: absolute;
      top: 50%;
      left: 20;
      transform: translateY(-50%);
    }
  }
}

.digitalDisplayList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -4px;
  .digitalDisplayListItem {
    flex-grow: 1;
    padding: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .name {
    font-size: 15px;
    font-weight: 600;
    color: rgba(68, 68, 68, 0.88);
    margin-bottom: 4px;
    text-transform: none;
  }
  .data {
    font-family: $fontFamilySecondary;
    font-size: 13px;
    font-weight: 500;
    color: rgba(68, 68, 68, 0.88);
    margin-bottom: 8px;
  }
  .qnt {
    font-size: 17px;
    font-weight: bold;
    color: rgba(68, 68, 68, 0.88);
    margin-right: 4px;
    word-break: break-all;
  }
  .displayContent {
    margin-top: auto;
  }
  .statusbar {
    display: block;
    height: 8px;
    width: 100%;
    border-radius: 4px;
  }
  .subtitle {
    margin-bottom: 8px;
    color: rgba(68, 68, 68, 0.56);
    font-size: 13px;
  }
}

.chartHeadingOptions {
  display: flex;
  align-items: center;
  .reverseSwitchHolder {
    //margin: 0 32px;
  }
}

.reverseSwitchHolder {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: rgba(68,68,68,.88);
}

@media (max-width: $screen-md) {
  .reverseSwitchHolder .reverseSwitcherLabel {
    font-size: 13px;
  }
}

@media (min-width: $screen-sm) {
  .digitalDisplayList {
    .digitalDisplayListItem {
      max-width: 50%;
    }
  }
}

@media (min-width: $screen-md) {
  .digitalDisplayList {
    .digitalDisplayListItem {
      max-width: 33.333%;
    }
  }
}

@media (min-width: $screen-lg) {
  .digitalDisplayList {
    .digitalDisplayListItem {
      max-width: 25%;
    }
  }
}

@media (min-width: $screen-lg) {
  .digitalDisplayList {
    .digitalDisplayListItem {
      max-width: 16.666%;
    }
  }
}

@import "dark-theme";

.mmsDashboardFilters {
  margin-bottom: 20px;
  display: flex;
  //justify-content: space-between;
  flex-direction: column;
  gap: 20px;

}

.emptyDataBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #5b5b5b;
  opacity: .2;
  .icon {
    font-size: 6em;
  }


}

.mmsDashboard {
  display: block;
  .pageCard {
    min-height: 300px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
    }

    .content {
      flex-grow: 1;
      height: 100px;
      //width: 100%;
      //height: 40px;
      //background-color: yellow;
    }
  }




  .title {
    color: #5b5b5b;
    margin-bottom: 10px;
    font-weight: bold;
    //color: rgba(255, 255, 255, 0.88);
  }

  .statSingleValue {
    position: relative;
    height: 100%;
    width: 100%;
    color: #5b5b5b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7em;
    font-weight: bold;
    gap: 10px;

    .icon {
      //position: absolute;
      font-size: 1em;
      opacity: .07;
      //bottom: -60%;
      //left: -60px;
      //display: none;
    }

    .value {
      margin-bottom: 20px;
      //font-size: 3em;
    }
  }
}

@media (min-width: $screen-xl) {
  .mmsDashboardFilters {
    flex-direction: row;
    justify-content: space-between;
    .field {
      min-width: 250px;
    }
  }

  .mmsDashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 290px 300px;
    gap: 20px ;

    .fullWidthBlock {
      grid-column-start: 1;
      grid-column-end: 4;

      .pageCard {
        height: 100%;
      }
    }

    .pageCard {
      margin-bottom: 0;
    }
  }
}

.lettered-avatar-wrapper .lettered-avatar {
  font-size: 17px;
  font-weight: bold;
}
