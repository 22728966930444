/*spacings*/

.mb64 {
  margin-bottom: 64px !important;
}

.mb56 {
  margin-bottom: 56px !important;
}

.mb48 {
  margin-bottom: 48px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb5 {
  margin-bottom: 5px!important;
}

.mb30 {
  margin-bottom: 30px!important;
}

.mb15 {
  margin-bottom: 15px!important;
}


.mb4 {
  margin-bottom: 4px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt30 {
  margin-top: 30px!important;
}

.mt32 {
  margin-top: 32px!important;
}

.mt22 {
  margin-top: 22px!important;
}

.mt16 {
  margin-top: 16px!important;
}

.mt8 {
  margin-top: 8px!important;
}

.mt5 {
  margin-top: 5px!important;
}

.mt10 {
  margin-top: 10px!important;
}

.ml16 {
  margin-left: 16px;
}

.mr16 {
  margin-right: 16px!important;
}

.mr8 {
  margin-right: 8px!important;
}

.ml15 {
  margin-left: 15px!important;
}

.pt0 {
  padding-top: 0!important;
}
.mr0 {
  margin-right: 0 !important;
}
.mr6 {
  margin-right: 6px !important;
}
.p0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.mr15 {
  margin-right: 15px;
}