.usersTable {
}

.tableDateFilter {
  padding: 0 32px 32px;
  max-width: 712px;
  width: 100%;
}

.tableHolderErrorIntegration {
  text-align: center;
  // color: red;
  margin-bottom: 20px;
  border: 1px solid rgb(243, 112, 112);
  background-color: rgb(243, 112, 112);
  width: fit-content;
  align-content: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
  border-radius: 5px;
}

.tableHolder {
  padding-bottom: 5px;
  overflow-x: auto;
  position: relative;
  min-height: 140px;
  .deleteTableItemBtn {
    //opacity: 0;
  }
  .viewLogsBtn {
    color: rgba(68, 68, 68, 0.88);
    font-weight: 100;
    font-family: monospace;
    font-size: 12px;
    margin-top: 1px;
  }
  .deleteTableItemDisabledBtn {
    opacity: 0.5;
  }
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    .MuiSvgIcon-root {
      fill-opacity: 0.56;
    }
    &.bordered {
      td {
        border: 1px solid rgba(68, 68, 68, 0.16);
        border-right: 0;
        border-left: 0;
      }
      th {
        border-bottom: none;
      }
    }
    tr {
      th,
      td {
        width: 7%;
        &:last-child {
          width: 100%;
        }
      }
    }
  }

  th {
    padding: 8px 16px;
    border-bottom: 1px solid rgba(68, 68, 68, 0.16);
    font-size: 12px;
    font-weight: 600;
    color: rgba(68, 68, 68, 0.88);
    cursor: pointer;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
    &.text-center {
      text-align: center;
    }
    &.hasArrow {
      position: relative;
      vertical-align: middle;
      &:before {
        content: "";
        display: inline-block;
        background: url("#{$imgPath}/arrow.svg") no-repeat;
        background-size: contain;
        background-position: center;
        height: 18px;
        width: 18px;
        opacity: 0.56;
        margin-top: -3px;
        vertical-align: middle;
      }

      &.up {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    &:hover {
      &.hasArrow {
        &:before {
          opacity: 0.88;
        }
      }
    }
  }
  .switchCell {
    padding-top: 0;
    padding-bottom: 0;
  }
  td {
    padding: 5px 15px;
    vertical-align: middle;
    font-size: 12px;
    color: rgba(68, 68, 68, 0.88);
    white-space: nowrap;
    &.text-center {
      text-align: center;
    }
  }

  .actionTableCell {
    text-align: right;
  }

  tr {
    &:not(.headingRow) {
      &:hover {
        background-color: rgba(68, 68, 68, 0.03);
        td {
          cursor: pointer;
        }
        .deleteTableItemBtn {
          opacity: 1;
        }
      }
    }
    &.inActive {
      td:not(.actionTableCell) {
        opacity: 0.4;
      }
    }
    &.disabled {
      td:not(.actionTableCell) {
        opacity: 0.4;
      }
    }
  }

  //tbody {
  //  tr {
  //    &:first-child {
  //      td {
  //        padding-top: 12px;
  //      }
  //    }
  //  }
  //}

  h3 {
    margin-bottom: 0;
    font-size: 12px;
  }
  .MuiIconButton-root + .MuiIconButton-root {
    margin-left: 15px;
  }
}

.tableControlsHolder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  color: #444444;
}

.sortHolder {
  display: flex;
  align-items: center;
  padding: 0 12px;
  color:  #969696;
  .caption {
    margin-right: 12px;
  }
  .MuiButton-text {
    color: #969696;
  }
}

.paginationHolder {
  padding: 0 12px;
  display: flex;
  align-items: center;
  color:  #969696;
  &.mms {
    padding-right: 0 !important;
  }
  .caption {
    margin-right: 12px;
  }
  .MuiIconButton-root.Mui-disabled {
    opacity: .5;
  }
}

@media (min-width: $screen-lg) {
  .tableHolder {
    th {
      font-size: 13px;
      font-weight: 600;
    }

    td {
      font-size: 13px;
    }

    .message-column {
      width: 30%;
      padding-right: 50px;
    }

    //tbody {
    //  tr {
    //    &:first-child {
    //      td {
    //        padding-top: 20px;
    //      }
    //    }
    //  }
    //}

    h3 {
      font-size: 13px;
    }
  }
}
