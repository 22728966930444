html body.theme-dark ::-webkit-scrollbar-thumb, body.theme-dark ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.16);
}

body.theme-dark {
  color-scheme: dark;
  background: #121212;
  color: rgba(255, 255, 255, 0.88);
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #252525 inset !important;
    border-radius: 0;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.56) !important;
  }
  .mainHeader {
    background: #252525;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  }
  .search {
    background-color: #252525;
  }
  .autocomplete .MuiOutlinedInput-input {
    color: rgba(255, 255, 255, 0.88) !important;
  }
  .autocomplete .MuiInputLabel-outlined {
    color: rgba(255, 255, 255, 0.88) !important;
  }
  .autocomplete .MuiOutlinedInput-input::placeholder {
    color: rgba(255, 255, 255, 0.88) !important;
  }
  .MuiAutocomplete-option {
    background-color: #383838;
    &:hover {
      background-color: rgb(22, 19, 19);
    }
  }

  .checkedCheckboxLabel {
    color: rgba(255, 255, 255, 0.88) !important;
  }
  
  .сheckboxLabel {
    color: rgba(255, 255, 255, 0.26) !important;
  }

  .modalFieldLabelSettings {
    .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgb(143, 143, 143) !important;
    }
  }

  .modalFieldLabelSettings {
    .MuiFormControl-root:hover:not(:focus) .MuiOutlinedInput-notchedOutline {
      border-color: rgb(143, 143, 143) !important;
      border: 0.1px solid !important;
    }
  }
  .workhoursUpdateModalTitle {
    color: rgba(255, 255, 255, 0.88);
  }

  .workhoursUpdateJobTitle {
    color: rgba(255, 255, 255, 0.88);
  }
  .activityForm {
    .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgb(143, 143, 143) !important;
    }
    .MuiFormControl-root .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgb(143, 143, 143) !important;
    }
    .formControlDatePicker:disabled {
      border: 1px solid rgb(120 120 120 / 16%);
      &:hover{
        border: 1px solid rgb(120 120 120 / 16%);
      }
    }
    .formControlDatePicker:hover {
      border-color: rgb(143, 143, 143);
    }
    .MuiOutlinedInput-root.Mui-disabled > .MuiOutlinedInput-notchedOutline {
      border: 1px solid rgb(120 120 120 / 16%) !important;
    }
    .uploadBtnLabel {
      color: rgba(255, 255, 255, 0.56);
      font-size: 13px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
    }
    .MuiSvgIcon-root {
      color: rgba(255, 255, 255, 0.26) !important;
    }
  }

  .completedCheckboxLabel {
    color: rgb(255 255 255 / 14%) !important;
  }

  .mobileMenuFooter {
    background-color: #383838;
  }
   
  .TableEmptyListMessage {
    background-color: #121212 !important;
  }
  .mainMenu {
    a {
      color: rgba(255, 255, 255, 0.56);
      &.active {
        color: $primaryColorDarkTheme;
      }
    }
  }
  .select-job-button {
    color: rgba(255, 255, 255, 0.88)!important;
  }
  .pageCard {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #252525;
    &.chartPageCard {
      background-color: #121212;
    }
  }
  .modalFieldLabelSettings {
    .MuiOutlinedInput-input {
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .modalFieldLabel {
    color: rgba(255, 255, 255, 0.88);
  }
  .modalTitle {
    color: rgba(255, 255, 255, 0.88);
  }
  h2 {
    color: rgba(255, 255, 255, 0.88);
  }
  .digitalDisplayList {
    .name {
      color: rgba(255, 255, 255, 0.88);
    }
    .subtitle {
      color: rgba(255, 255, 255, 0.88);
    }
    .data {
      color: rgba(255, 255, 255, 0.88);
    }
    .qnt {
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .templateIcon {
    background-image: url("#{$imgPath}/outline-template-24px-white.svg");;
  }
  .langIcon {
    background-image: url("#{$imgPath}/outline-language-18px-white.svg");
  }
  .jobIcon {
    background-image: url("#{$imgPath}/round-job_list-24px-white.svg");
  }
  .dotsIcon {
    background-image: url("#{$imgPath}/more-vert-24px-white.svg");
  }
  .removeIcon {
    background-image: url("#{$imgPath}/delete-white.svg");
  }
  .copyIcon {
    background-image: url("#{$imgPath}/icoCopyWhite.svg");
  }
  .timeIcon {
    background-image: url("#{$imgPath}/round-time-24px-white.svg");
  }
  .axisIcon {
    background-image: url("#{$imgPath}/straighten-24px-white.svg");
  }
  .calendarIcon {
    background-image: url("#{$imgPath}/outline-date_range-24px-white.svg");
  }
  .filterIcon {
    background-image: url("#{$imgPath}/round-filter_list-24px-white.svg");
  }
  .plusIcon {
    &.primary {
      background-image: url("#{$imgPath}/round-add-24px-secondary.svg");
    }
  }
  .checkboxListContainer {
    border-color: rgba(255, 255, 255, 0.16);
    label {
      &:hover {
        background-color: lighten(#383838, 3%);
      }
    }
    .channel-list-item, .listItemWithRemove {
      &:hover {
        background-color: lighten(#383838, 3%);
      }
      label {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .checkboxListHolder {
    & > .labelLike {
      background-color: #383838;
      color: rgba(255, 255, 255, 0.56);
    }
  }
  .unit-list-item {
    .title {
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .optionsSwitch {
    border: solid 1px rgba(255, 255, 255, 0.24);
    &:before {
      background: $primaryColorDarkTheme;
    }
    .option {
      color: rgba(255, 255, 255, 0.56);
      &:first-child {
        color: #fff;
      }
    }
    &.in {
      .option {
        color: #fff;
        &:first-child {
          color: rgba(255, 255, 255, 0.56);
        }
      }
    }
  }
  .formHolder  {
    label,
    .labelLike {
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .themesList {
    li.dark:before {
      border: 1px solid rgba(255,255,255,.56);
    }
  }
  .langList {
    li {
      &:before {
        border: solid 1px rgba(255, 255, 255, 0.16);
      }
      &:after {
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .unit-list-item {
    .title {
      .arrow {
        background-image: url("#{$imgPath}/round-keyboard_arrow_down-24px-white.svg");
      }
    }
  }
  .copyright {
    color: rgba(255, 255, 255, 0.56);
  }
  .reverseSwitchHolder {
    color: rgba(255,255,255,.88);
  }
  .titleLimitSymbols {
    color: #fff;
  }
  .customDateRangeBtn {
    color: #fff !important;
  }
  .filterMenuItem {
    color: #fff;
  }
  .dateRangePicker .MuiOutlinedInput-input {
    cursor: pointer !important;
    color: #fff;
}
  .mainLayout {
    &.introImg {
      .mainHeader {
        background: #2b2b2b;
      }
    }
  }
  .videoHolder {
    video {
      &.lightThemeVideo {
        display: none;
      }
      &.darkThemeVideo {
        display: block;
      }
    }
  }
  .setHeadingInput .MuiInputBase-input {
    font-weight: 600;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.88);
    padding: 6px 0 6px;
  }
  .calendarHolder {
    & > div {
      background-color: #383838;
      & > div {
        & > div:nth-child(2) {
          & > * {
            button {
              background-image: url("#{$imgPath}/round-keyboard_arrow_left-24px-white.svg");
              background-size: 24px;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }
    }
    [data-testid="DatepickerNavButton"] {
      background: #383838;
      svg {
        color: rgba(255,255,255,0.56);
      }
    }
    [data-testid="MonthLabel"] {
      color: rgba(255,255,255,0.88);
    }
    [data-testid="DayLabel"] {
      color: rgba(255,255,255,0.56);
    }
    [data-testid="Day"] {
      color: rgba(255,255,255,0.88);
      background-color: #383838;
    }
  }
  .authFromBlock {
    background: #252525;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px #252525 inset !important;
      border-radius: 0;
      -webkit-text-fill-color: rgba(255, 255, 255, 0.56) !important;
    }
  }
  .linkPrimary {
    color: $primaryColorDarkTheme !important;
  }
  .linkGray {
    color: rgba(255, 255, 255, 0.88) !important;
  }
  .btnPrimary {
    background: $primaryColorDarkTheme;
    border-color: $primaryColorDarkTheme;
  }
  .tableHolder {
    th {
      color: rgba(255, 255, 255, 0.88);
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      &.hasArrow {
        &:before {
          background: url("#{$imgPath}/arrow-white.svg") no-repeat;
        }
      }
    }
    td {
      color: rgba(255, 255, 255, 0.88);
    }
    tr {
      &:not(.headingRow):hover {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }
  }
  .userInfo {
    .userName {
      color: rgba(255, 255, 255, 0.56);
    }
  }
  .radioLabel {
    color: rgba(255, 255, 255, 0.88);
    &.MuiFormLabel-root.Mui-focused {
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .tableControlsHolder {
    color: rgba(255, 255, 255, 0.88);
  }
  .arrowLeftIcon {
    background-image: url("#{$imgPath}/round-keyboard_arrow_left-24px-white.svg");
  }
  .arrowRightIcon {
    background-image: url("#{$imgPath}/round-keyboard_arrow_left-24px-white.svg");
  }
  .arrowDownIcon {
    background-image: url("#{$imgPath}/round-keyboard_arrow_down-24px-white.svg");
  }
  .doubleInput {
    .MuiSvgIcon-root {
      fill: rgba(255,255,255,.88);
    }
    .MuiOutlinedInput-root {
      &:not(.Mui-disabled):hover,
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba(255, 255, 255, 0.16) !important;
        }
      }
    }
    .last {
      &:before {
        background: rgba(255,255,255,.16);
      }
    }
  }
  .axisSettingsTableLike {
    .doubleInput {
      .MuiSvgIcon-root {
        fill: rgba(255,255,255,.88);
      }
      .MuiOutlinedInput-root {
        &:not(.Mui-disabled):hover,
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.16) !important;
          }
        }
      }
      .last {
        &:before {
          background: rgba(255,255,255,.16);
        }
      }
    }
    .tr {
      &.hasBorderBottom {
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      }
    }
  }
  .scrollContentModal {
    .formContentBlock {
      border-color: rgba(255, 255, 255, 0.16);
    }
    .btnHolder {
      border-color: rgba(255, 255, 255, 0.16);
    }
    .modalTitleHolder {
      border-color: rgba(255, 255, 255, 0.16);
    }
  }
  .fill {
    border-radius: 10px;
    background: #202020;
    border: 1px solid #202020;
    canvas {
      width: calc(100% + 10px)!important;
      left: -5px;
    }
    &:before {
      background: #252525;
      height: 6px;
    }
    &:after {
      background: #252525;
      height: 6px;
    }
  }
  .contextMenuIcon {
    color: rgba(151,151,151,.88);
  }
  .pageBtnSection {
    .part {
      &.centered {
        p {
          color: rgba(255,255,255,.88);
        }
        button {
          color: rgba(255,255,255,.88);
        }
      }
    }
  }
  .showOnlyTitle {
    color: rgba(255,255,255,.88);
  }
  .radioButtonLabel {
    color: rgba(255,255,255,.88) !important;
  }

  .radioButtonLabel input[type=radio] {
    accent-color: rgba(255,255,255,.88);
}
  .dragIcon {
    background-image: url("#{$imgPath}/move-dark.svg");
  }
  .tabsLike {
    .tabsLikeItem {
      color: rgba(255, 255, 255, 0.56);
      &.active {
        color: $primaryColorDarkTheme;
      }
    }
  }
  .resendIcon {
    background-image: url("#{$imgPath}/round-resend_outline-24px-white.svg");
  }
  .subNavIcon {
    background-image: url("#{$imgPath}/pred-main/dashboard-white.svg");
  }
  .amCompIcon {
    background-image: url("#{$imgPath}/pred-main/dashboard2-white.svg");
  }
  .amEquipmentIcon {
    background-image: url("#{$imgPath}/pred-main/equipment-white.svg");
  }
  .amCatalogIcon {
    background-image: url("#{$imgPath}/pred-main/calendar_week-white.svg");
  }
  .amActIcon {
    background-image: url("#{$imgPath}/pred-main/list_check-white.svg");
  }
  .amSchIcon {
    background-image: url("#{$imgPath}/pred-main/bar_chart_square-white.svg");
  }
  .amEditIcon {
    background-image: url("#{$imgPath}/pred-main/bar_chart_square-white.svg");
  }
  .amPlIcon {
    background-image: url("#{$imgPath}/pred-main/plus_square_dark-white.svg");
  }
  .amUsIcon {
    background-image: url("#{$imgPath}/pred-main/user_plus-white.svg");
  }
  .amReportIcon {
    background-image: url("#{$imgPath}/pred-main/scheduled_reports-white.svg");
  }
  .amExportReportIcon {
    background-image: url("#{$imgPath}/pred-main/download-report-white.svg");
  }
  .amDplIcon {
    background-image: url("#{$imgPath}/pred-main/copy-white.svg");
  }
  .amRemoveIcon {
    background-image: url("#{$imgPath}/pred-main/trash_full-white.svg");
  }
  .amChangeIcon {
    background-image: url("#{$imgPath}/pred-main/change-white.svg");
  }
  .icoInnerRow {
    background-image: url("#{$imgPath}/pred-main/sub_right-white.svg");
  }
  .companyInfoItemHolder {
    .captionHolder {
      .title {
        color: rgba(255, 255, 255, 0.88);
      }
      .subTitle {
        color: rgba(255, 255, 255, 0.88);
      }
    }
    .companyBtnHolder {
      .MuiIconButton-root {
        background-color: #252525;
      }
    }
  }
  .predMainLayout {
    .mainAside, .secondaryAside {
      background: #252525;
      border-color: rgba(255, 255, 255, 0.08);
    }
    .mainHeader {
      border-color: rgba(255, 255, 255, 0.08);
    }
    .subNavHolder {
      border-color: rgba(255, 255, 255, 0.08);
    }
    .asideNavItem {
      &:hover, &.active {
        background-color: #383838;
        color: #fff;
      }
    }
    .pmTabs {
      .MuiTab-root {
        color: rgba(255, 255, 255, 0.88);
        &.Mui-selected {
          color: #0094c2;
        }
      }
      &.MuiTabs-root {
        &:after {
          background-color: rgba(255, 255, 255, 0.16);
        }
      }
    }
    .pmPageBlockTitle {
      color: rgba(255, 255, 255, 0.88);
    }
    .pmSubHeader {
      .title {
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
  .pmTable {
    th {
      color: rgba(255, 255, 255, 0.88);
      &.hasArrow {
        &:before {
          background: url("#{$imgPath}/arrow-white.svg") no-repeat;
        }
      }
    }
    td {
      background-color: #252525;
      border-color: #393939;
      color: rgba(255, 255, 255, 0.88);
    }
    tr {
      &:hover {
        td {
          background-color: #393939;
        }
      }
    }
    .MuiFormControl-root {
      .MuiOutlinedInput-input {
        background-color: transparent;
      }
    }
    .activitiesItem {
      color: rgba(255, 255, 255, 0.88);
      &:before {
        background-image: url("#{$imgPath}/pred-main/list_check-white.svg");
      }
      &:after {
        background-image: url("#{$imgPath}/pred-main/ascending-white.svg");
      }
    }
    .statusItem {
      color: rgba(255, 255, 255, 0.88);
    }
    .statusItem.overdue {
      color: #DF1A1A;
    }
    .statusItem.on {
      color: #6cc147;
    }
    .statusItem.upcoming {
      color: #C7C735;
    }
    .statusItem.open {
      color: #006ae7;
    }
    .statusItem.off {
      color: #fff;
    }
    .fieldReady {
      background-color: transparent !important;
    }
    .toRepair {
      background-color: transparent !important;
    }
    .toCheck {
      background-color: transparent !important;
    }
    .disposed {
      background-color: transparent !important;
      color: #fff !important;
      border-color: #fff !important;
    }

    .state-item-table:after {
      background-image: url("#{$imgPath}/pred-main/ascending-white.svg");
  }
    .addActivityBtn {
      &:hover {
        background-color: rgba(255, 255, 255, 0.04);
      }
    }
    .activitiesRow {
      .activitiesTable {
        th {
          background-color: #383838;
        }
        tr {
          &:nth-child(odd) {
            td {
              background: #3e3e3e !important;
            }
          }
          &:nth-child(even) {
            td {
              background: #383838 !important;
            }
          }
        }
      }
    }
    .btnCell {
      .MuiSvgIcon-root {
        color: #fcfcfc;
      }
      .Mui-disabled {
        .MuiSvgIcon-root {
          color: rgba(252, 252, 252, .56);
        }
      }
    }
  }
  
  .Successful {
    background-color: transparent !important;
    border-color: #48AF8A !important;
    color: #48AF8A !important;
  }

  .Error {
    background-color: transparent !important;
    border-color: #ff6c6c !important;
    color: #ff6c6c !important;
  }

  .logsModal {
    .MuiPaper-root {
      background-color: #121212 !important;
      border: solid 1px rgba(255, 255, 255, 0.24);
    }
  }
  
  .logsTable {
    background-color: #383838 !important;
    color: white !important;
  }

  .viewLogsBtn {
    color: white !important;
  }

  .viewLogsBtn:hover {
    opacity: 0.8;
  }

  .chooseUnit:hover {
    background: transparent !important;
  }

  .unit-list-item .channel-list-item .MuiButton-text:hover  {
    padding: 4px 16px;
    background: rgb(89, 89, 89) !important;
  }

  .activitiesRow {
    .activitiesRowContent {
      &:hover {
        background: #252525 !important; 
      }
    }

  }

  .channel-list-item,
  .listItemWithRemove {
    &:hover {
      background-color: darken(#fff, 3%);
       .MuiButton-root {
        background-color: transparent !important;
      }
    }

    label {
      &:hover {
        background-color: transparent;
      }
    }
  }
 
  .equipmentSubMenu {
    .menuItem {
      &.add {
        &:before {
          background-image: url("#{$imgPath}/pred-main/plus_square-white.svg");
        }
      }
      &.edit {
        &:before {
          background-image: url("#{$imgPath}/pred-main/edit-white.svg");
        }
      }
      &.duplicate {
        &:before {
          background-image: url("#{$imgPath}/pred-main/copy-white.svg");
        }
      }
      &.changeDepartment {
        &:before {
          background-image: url("#{$imgPath}/pred-main/low-priority-white.svg");
        }
      }
      &.replace {
        &:before {
          background-image: url("#{$imgPath}/pred-main/replace-icon-white.svg");
        }
      }
      &.change {
        &:before {
          background-image: url("#{$imgPath}/pred-main/change-white.svg");
        }
      }
      &.delete {
        &:before {
          background-image: url("#{$imgPath}/pred-main/trash_full-white.svg");
        }
      }
      &.mark {
        &:before {
          background-image: url("#{$imgPath}/pred-main/mark-as-completed-white.svg");
        }
      }
      &.assign {
        &:before {
          background-image: url("#{$imgPath}/pred-main/assign-person-white.svg");
        }
      }
    }
  }
  .state-item-table {
    opacity: 1;
  }
  .state-item {
    opacity: 1;
    font-size: 13px;
    background-color: transparent !important;
  }

  .state-item.disposed {
    background-color: transparent !important;
    color: #fff !important;
    border-color: #fff !important;
  }

  .pageFilter {
    .MuiOutlinedInput-root {
      background-color: #252525;
    }
  }
  .activitiesFilterHolder {
    .filterHeading {
      .pmPageBlockTitle {
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
  .textGray {
    color: rgba(255, 255, 255, 0.56);
  }
  .activitiesTaskTable {
    .taskNameHolder {
      border-color: transparent;
      background: transparent;
      .icoHolder {
        background-image: url("#{$imgPath}/pred-main/infoicon-white.svg");
      }
    }
    &.pageTable {
      th {
        background-color: #383838;
      }
      tr {
        &:nth-child(odd) {
          td {
            background: #3e3e3e !important;
          }
        }
        &:nth-child(even) {
          td {
            background: #383838 !important;
          }
        }
      }
    }
  }
  .partsListGroup {
    border-color: rgba(255, 255, 255, 0.16);
    &:hover {
      border-color: rgb(143, 143, 143) !important;
    }
  }
  .MuiFormLabel-root {
    color: rgba(255, 255, 255, 0.56)!important;
    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.28)!important;
    }
  }
  .formControlDatePicker {
    border-color: rgba(255, 255, 255, 0.16);
    color: rgba(255, 255, 255, 0.88);
  }
  .datePickerWithLabel {
    .labelLike {
      background-color: #383838;
      color: rgba(255, 255, 255, 0.56);
    }
  }

  .MuiListItem-button {
    &:hover {
      background-color: rgba(18,18,18,.7);
    }
  }
  .textFieldTableHolder {
    .textFieldTable {
      .btnCell {
        .MuiSvgIcon-root {
          color: #fcfcfc;
        }
        .Mui-disabled {
          .MuiSvgIcon-root {
            color: rgba(252, 252, 252, .56);
          }
        }
      }
    }
  }
  .MuiIconButton-root.Mui-disabled {
    color: rgba(252, 252, 252, .56);
  }
  .MuiIconButton-root.Mui-disabled svg {
    color: #5656568f !important;
  }
  .react-datepicker {
    background-color: #383838;
    color: #fff;
    border-color: #585858;
    .react-datepicker__header {
      background-color: #252525;
      border-color: #585858;
      .react-datepicker__day-name {
        color: #fff;
      }
    }
    .react-datepicker__time-container {
      border-left: 1px solid #585858;
      .react-datepicker__time {
        background: #383838;
        .react-datepicker__time-box {
          ul.react-datepicker__time-list {
            li {
              &.react-datepicker__time-list-item {
                &:hover {
                  background-color: #252525;
                }
              }
            }
          }
        }
      }
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: #fff;
    }
    .react-datepicker__triangle {
      border-bottom-color: #252525;
      &:before {
        border-bottom-color: #585858;
      }
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      color: #fff;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      color: rgba(255,255,255,.16);
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background-color: #252525;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: #0094c2;
    }
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336!important;
  }
  .mainMenu {
    &.mobile {
      .MuiListItem-root {
        &:hover {
          background-color: #252525;
        }
      }
    }
  }
  .companySettingsCard {
    background-color: #252525;
    border: none;
  }
  .CompanySettingsTitle {
    color: rgba(255, 255, 255, 0.88);
  }
  .companySettingsCardUserName {
    color: rgba(255, 255, 255, 0.88);
  }
  .companySettingsCardJobName {
    color: rgba(255, 255, 255, 0.88);
  }
  .companySettingsCardTimeZone {
    color: rgba(255, 255, 255, 0.88);
  }
  .companySettingsCardBtn {
    color: #0094c2;
  }
  .companySettingsCheckboxTitle {
    color: rgba(255, 255, 255, 0.88);
  }
  .companySettingsCheckboxSubTitle {
    color: rgba(255, 255, 255, 0.88);
  }
  .companySettingsCSelectorTitle {
    color: rgba(255, 255, 255, 0.88);
  }
}
