$fontFamily: 'Open Sans', sans-serif;
$fontFamilySecondary: 'Open Sans', sans-serif;


/* Responsive screen width */

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$screen-small: 320px;
$screen-med: 768px;
$screen-large: 992px;
$screen-xlarge: 1200px;

/* Color vars */

$primaryColor: #006ae7;
$secondaryColor: #228ebd;

$primaryColorDarkTheme: #0094c2;

$black: #000;
$gray300: #eeeded;
$white: #fff;

$warningColor: #d31414;
$requestColor: #2b9d02;
$successColor: #0ea050;
$errorColor: #e72222;
$warningColorToaster: #ff8a00;

$borderColor: $gray300;

/*Responsive font size*/

$fontSize: 13px;
$fontSizeMute: 13px;
$fontSizeLead: 17px;
$fontSizeHeading: 18px;
$fontSizeSubheading: 18px;

$imgPath: './../../../assets/img';
$imPathPm: './../../../../assets/img';
