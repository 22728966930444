.templateIcon {
  display: block;
  width: 18px;
  height: 18px;
  flex-basis: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("#{$imgPath}/outline-template-24px.svg");;
}

.langIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/outline-language-18px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.jobIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/round-job_list-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.dotsIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/more-vert-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}
.icoInnerRow {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/sub_right.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
  flex-shrink: 0;
}

.subNavIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/dashboard.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amCompIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/dashboard2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amCatalogIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/calendar_week.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amEquipmentIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/equipment.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amActIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/list_check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amSchIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/bar_chart_square.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amPlIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/plus_square_dark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amUsIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/user_plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amDplIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/copy.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amChangeIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/change.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amRemoveIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/trash_full.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amReportIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/scheduled_reports.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amEditIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/edit.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.amExportReportIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/pred-main/download-report.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.plusIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/round-add-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
  &.primary {
    background-image: url("#{$imgPath}/round-add-24px-primary.svg");
  }
}

.dragIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/move.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  flex-basis: 20px;
}

.removeIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/round-delete_outline-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.copyIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/icoCopy.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 20px;
}

.resendIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$imgPath}/round-resend_outline-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 19px;
  flex-basis: 20px;
}

.timeIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/round-time-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.timeIconWithArrow {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/time.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.calendarIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/outline-date_range-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.filterIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/round-filter_list-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.axisIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/straighten-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.editIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/edit-24x24.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.saveIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/round-check-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.arrowDownIcon {
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("#{$imgPath}/round-keyboard_arrow_down-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.arrowLeftIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/round-keyboard_arrow_left-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
}

.arrowRightIcon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("#{$imgPath}/round-keyboard_arrow_left-24px.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-basis: 18px;
  transform: rotate(180deg);
}

.BlueThemeIcon {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: relative;
  background-color: #006ae7;
  &:after {
    content: "";
    display: block;
    background-image: url("#{$imgPath}/bold-round-check-18px.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 2px;
    margin-top: -9px;
    transition: transform .2s;
  }
}

.DarkThemeIcon {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: relative;
  background-color: #252525;
  &:after {
    content: "";
    display: block;
    background-image: url("#{$imgPath}/bold-round-check-18px.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 2px;
    margin-top: -9px;
    transition: transform .2s;
  }
}





