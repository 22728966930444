.pageBlockHolder + .pageBlockHolder {
  margin-top: 8px;
}

.pageBlockHolder + .combinedPageBlock {
  margin-top: 8px;
}

.combinedPageBlock + .combinedPageBlock {
  margin-top: 8px;
}

.combinedPageBlock + .pageBlockHolder {
  margin-top: 8px;
}

.contextMenuIcon {
  color: rgba(68,68,68,.88);
}

.pageBlockHolder {
  margin-top: 10px;
}



@media (min-width: $screen-lg) {
  .dragging {
    padding-bottom: 45px;
    //background-color: red;
  }
  .combinedPageBlock {
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc(50% - 4px);
      & > div {
        height: 100%;
      }
    }
    .pageBlock {
      height: 100%;
    }
    .pageCard {
      height: 100%;
    }
    .pageBlockHolder + .pageBlockHolder {
      margin-top: 0;
    }
    .digitalDisplayList {
      .digitalDisplayListItem {
        max-width: 33.333%;
      }
    }
  }
}

@media (min-width: $screen-xl) {
  .combinedPageBlock {
    .digitalDisplayList {
      .digitalDisplayListItem {
        max-width: 50%;
      }
    }
  }
}